import workbench from '../router/mudules/workbench'
import personal_center from '../router/mudules/personal-center'
import personnel_management from '../router/mudules/personnel-management'
import oam_services from '../router/mudules/oam-services'
import safe_passage from '../router/mudules/safe-passage'
import staff_dorm from '../router/mudules/staff-dorm'
import order_food_online from '../router/mudules/order-food-online'
import system_management from '../router/mudules/system-management'
import security_collaborative_management from '../router/mudules/security-collaborative-management'
import secure_portal_docking from '../router/mudules/secure-portal-docking'

// const demo = {
//   path: 'oam-services',
//   icon: '',
//   visible: '0',
//   redirect: '/oam-services/device-management',
//   menuName: '运维服务',
//   component: 'ModuleLayout',
//   children: [
//     {
//       path: 'oam-services',
//       icon: '',
//       visible: '0',
//       redirect: '',
//       menuName: '设备管理',
//       component: '',
//       children: [
//         {
//           path: 'device-management',
//           icon: '',
//           visible: '0',
//           redirect: '',
//           menuName: '设备管理',
//           component: 'oam-services/device-management/device-management'
//         },
//         {
//           path: 'device-details',
//           icon: '',
//           visible: '1',
//           redirect: '',
//           menuName: '设备详情',
//           component: 'oam-services/device-management/device-details'
//         }
//       ]
//     }
//   ]
// }

export const asyncRouterMap = [
  ...workbench,
  ...personal_center,
  ...personnel_management,
  ...oam_services,
  ...safe_passage,
  ...staff_dorm,
  ...order_food_online,
  ...system_management,
  ...security_collaborative_management,
  ...secure_portal_docking
]
