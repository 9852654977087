<template>
  <el-dialog
    v-model="visible"
    title="常见问题"
    :top="'8vh'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="640px">
    <div class="collection-list">
      <el-scrollbar>
        <div v-for="(item, index) in tableData" :key="index" class="item">
          <div>{{ indexMethod(index) + '、' + item.problem }}</div>
          <div>{{ '答：' + item.answer }}</div>
        </div>
      </el-scrollbar>
    </div>
    <div class="pagination">
      <el-pagination
        v-model:currentPage="formData.pageNum"
        v-model:page-size="formData.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :default-page-size="10"
        small
        background
        layout="total,sizes, prev, pager, next, jumper"
        :total="formData.total"
        class="myTablePagination"
        @size-change="handleSizeChange"
        @current-change="handlePageChange" />
    </div>
    <template v-slot:footer>
      <el-button @click="visible = false" type="primary" plain>关闭</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref } from 'vue'
  import { getCommonProblemList } from '@/api/system-management/common-problem'

  const visible = ref(false)
  const tableData = ref([])
  const formData = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const init = async () => {
    visible.value = true
    getList()
  }

  const getList = async () => {
    const res = await getCommonProblemList(formData)
    formData.total = res.total
    tableData.value = res.rows
  }

  const handlePageChange = (pageNum) => {
    formData.pageNum = pageNum
    getList()
  }

  const handleSizeChange = (size) => {
    formData.pageSize = size
    formData.pageNum = 1
    getList()
  }
  const indexMethod = (index) => {
    return index + (formData.pageNum - 1) * formData.pageSize + 1
  }

  // 将方法、变量暴露给父组件使用，父组见才可通过ref API拿到子组件暴露的变量或方法
  defineExpose({
    init
  })
</script>

<style lang="scss" scoped>
  .collection-list {
    height: 500px;
    .item {
      font-size: 16px;
      color: #333333;
      line-height: 40px;
      border-bottom: 1px dashed #ececec;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    // padding: 20px;
  }
</style>
