/**
 * 构造树型结构数据
 * @param {*} source 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function treeData(source, id, parentId, children, rootId) {
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'
  rootId = rootId || '0'
  const cloneData = JSON.parse(JSON.stringify(source)) // 对源数据深度克隆
  return cloneData.filter((father) => {
    const branchArr = cloneData.filter((child) => father[id] === child[parentId]) // 返回每一项的子级数组
    branchArr.length > 0 ? (father[children] = branchArr) : delete father[children] // 如果存在子级，则给父级添加一个children属性，并赋值
    return father[parentId] === rootId // 返回第一层
  })
}

/**
 * 清理无用树枝
 *
 * @export
 * @param {*} source 树
 * @param {*} children 孩子的key
 */
export function treeDataFormat(source, children) {
  children = children || 'children'

  if (source && source.length > 0) {
    for (const item of source) {
      if (item.level && item.level < 5) {
        if (item[children] && item[children].length === 0) {
          item.disabled = true
        }
      }

      if (item[children] && item[children].length === 0) {
        delete item[children]
      }

      if (item[children] && item[children].length > 0) {
        treeDataFormat(item[children])
      }
    }
  }
}

/**
 * 格式化部门树数据
 *
 * @export
 * @param {*} list
 * @param {*} arr
 * @param {*} parentId
 */
export function buildtree(list, arr, parentId) {
  list.forEach((item) => {
    if (item.parentId === parentId) {
      const child = {
        key: item.deptId,
        value: item.deptId + '',
        title: item.deptName,
        children: []
      }
      buildtree(list, child.children, item.deptId)
      arr.push(child)
    }
  })
}
/**
 * 路由排序
 *
 * @export
 * @param {*} data 路由数组
 */
export function sortTree(data) {
  data.sort((a, b) => {
    return a.orderNum - b.orderNum
  })
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      sortTree(item.children)
    }
  }
}
/**
 * 格式化菜单树数据，带插槽结构
 *
 * @export
 * @param {*} list 1级数组
 * @param {*} permissions 权限数组
 * @param {*} parentId 父id
 */
export function buildSlotsTree(list, permissions, parentId, disabled = false) {
  list.forEach((item) => {
    if (item.parentId === parentId) {
      const child = {
        key: item.id,
        children: [],
        title: item.menuName,
        orderNum: item.orderNum,
        scopedSlots: { title: 'title' },
        disabled
      }
      buildSlotsTree(list, child.children, item.id, disabled)
      permissions.push(child)
    }
  })
}

/**
 * 数组转树形结构
 * 最优性能 map 存储
 *
 * @param {*} list 扁平数组
 * @param {*} parentId 顶级父Id
 * @return {*}
 */
export function arrayToTree(list, parentId) {
  const result = [] // 存放结果集
  const itemMap = {} // map 存储
  for (const item of list) {
    const id = item.id
    const pid = item.parentId

    if (!itemMap[id]) {
      itemMap[id] = {
        children: []
      }
    }

    itemMap[id] = {
      ...item,
      key: item.menuKey,
      path: item.path || item.menuKey,
      name: item.menuKey,
      children: itemMap[id]['children']
    }

    const treeItem = itemMap[id]

    if (pid === parentId) {
      result.push(treeItem)
    } else {
      if (!itemMap[pid]) {
        itemMap[pid] = {
          children: []
        }
      }
      itemMap[pid].children.push(treeItem)
    }
  }
  return result
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}
