<template>
  <!-- <div class="notice-detail"> -->
  <el-dialog
    v-model="dialogVisible"
    title="消息详情"
    width="800px"
    @closed="handleClose"
    custom-class="notice-detail-dialog">
    <div class="notice-title">
      <div class="date">通知时间： {{ form.createTime }}</div>
    </div>
    <div class="div-class">{{ form.messageContent }}</div>
    <div v-if="form.processId" class="title-class">审核信息</div>
    <el-table
      v-if="form.processId"
      :data="tableData"
      v-loading="loading"
      class="myTable"
      stripe
      border
      style="width: 100%">
      <el-table-column label="序号" type="index" align="center" width="80" />
      <el-table-column prop="auditorUserName" label="姓名" align="center">
        <template v-slot="{ row }">
          {{ row.auditorUserName ? row.auditorUserName : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="审核结果" align="center">
        <template v-slot="{ row }">
          <!-- 0待审批 1审批中 2通过 3拒绝 -->
          {{
            row.state == 0
              ? '待审批'
              : row.state == 1
              ? '审批中'
              : row.state == 2
              ? '通过'
              : row.state == 3
              ? '拒绝'
              : row.state
              ? row.state
              : '--'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="auditTime" label="审核时间" align="center">
        <template v-slot="{ row }">
          {{ row.auditTime ? row.auditTime : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="comment" label="审核意见" align="center">
        <template v-slot="{ row }">
          {{ row.comment ? row.comment : '--' }}
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button type="primary" @click="handleClose">关闭</el-button>
    </template>
  </el-dialog>
  <!-- </div> -->
</template>

<script setup>
  import { ref } from 'vue'
  import { getMessage } from '@/api/workbench/index'
  // 接口
  import { getProcessApply } from '@/api/conference-management/conference-management'

  const dialogVisible = ref(false)
  const loading = ref(false)
  const form = ref({})
  const tableData = ref([])
  const emit = defineEmits(['ok'])

  const getDetail = (item) => {
    tableData.value = []
    dialogVisible.value = true
    if (item.id) {
      getMessage(item.id)
        .then((res) => {
          if (res.code == '00000') {
            form.value = JSON.parse(JSON.stringify(res.data))
          }
        })
        .finally(() => {
          if (form.value.processId) {
            getList(form.value)
          }
        })
    } else {
      form.value = JSON.parse(JSON.stringify(item))
      if (item.processId) {
        getList(form.value)
      }
    }
  }

  const getList = async (row) => {
    loading.value = true
    const res = await getProcessApply(JSON.parse(JSON.stringify(row.processId)))
    if (res && res.code == '00000' && res.data) {
      if (res.data.createByName) {
        tableData.value.push({
          auditorUserName: res.data.createByName + '（发起人）',
          state: null,
          auditTime: '',
          comment: ''
        })
      }
      if (res.data.copyNodes) {
        tableData.value.push({
          auditorUserName: res.data.copyNodes + '（抄送人）',
          state: null,
          auditTime: '',
          comment: ''
        })
      }
      const arr = res.data.processNodeVos || []
      if (arr) {
        arr.forEach((e) => {
          if (e.auditors && e.auditors.length > 0) {
            e.auditors.forEach((el) => {
              tableData.value.push({
                auditorUserName: el.auditorUserName + '（审核人）',
                state: el.state,
                auditTime: el.auditTime,
                comment: el.comment
              })
            })
            if (e.state != 2 && e.name) {
              tableData.value.push({
                auditorUserName: e.name + '（审核人）',
                state: 0,
                auditTime: '',
                comment: ''
              })
            }
          } else {
            if (e.name) {
              tableData.value.push({
                auditorUserName: e.name + '（审核人）',
                state: 0,
                auditTime: '',
                comment: ''
              })
            }
          }
        })
      }
    }
    loading.value = false
  }

  const handleClose = () => {
    dialogVisible.value = false
    emit('ok')
  }
  defineExpose({ getDetail })
</script>
<style lang="scss">
  .notice-detail-dialog {
    .el-dialog__body {
      padding-top: 0;
      margin-top: 10px;
    }
  }
</style>
<style lang="scss" scoped>
  // .notice-detail {
  //   :deep(.el-dialog__body) {
  //     padding-top: 0;
  //     margin-top: 10px;
  //   }
  // }
  .notice-title {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .date {
      font-size: 12px;
      color: #a7a7a7;
    }
  }

  .div-class {
    margin-bottom: 10px;
  }

  .title-class {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    border-top: 1px solid #ececec;
  }
</style>
