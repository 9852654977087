const getters = {
  addRoutes: (state) => state.permission.addRoutes,
  configRoutes: (state) => state.permission.menuRoutes,
  token: (state) => state.user.token,
  parkId: (state) => state.user.parkId,
  userId: (state) => state.user.userId,
  userInfo: (state) => state.user.userInfo,
  roles: (state) => state.permission.routePermission,
  parkList: (state) => state.user.parkList,
  allParkList: (state) => state.user.allParkList,
  flatMenu: (state) => state.permission.flatMenu,
  parkOptions: (state) => {
    const list = Object.assign([], state.user.parkList)
    const result = [...list]
    if (list.length > 1) {
      const obj = {
        parkId: '',
        parkName: '全部'
      }
      state.user.parkList.forEach((p) => {
        obj.parkId = obj.parkId + p.parkId + ','
      })
      obj.parkId = obj.parkId.slice(0, obj.parkId.length - 1)
      result.unshift(obj)
    }
    return result
  },
  messageState: (state) => state.user.messageState,
  noPermission: (state) => state.user.noPermission,
  shareRoomTab: (state) => state.user.shareRoomTab,
  isCollapse: (state) => state.user.isCollapse
}

export default getters
