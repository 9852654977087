// 在线订餐
const ROUTES = [
  {
    path: 'order-food-online',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/order-food-online/menu-management',
    menuName: '在线订餐',
    component: 'ModuleLayout',
    children: [
      {
        path: 'order-food-online',
        icon: 'List',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '菜单管理',
        component: '',
        children: [
          {
            path: 'menu-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '菜单管理',
            component: 'order-food-online/menu-management/menu-management'
          },
          {
            path: 'expense-allocation',
            icon: '',
            visible: '1',
            menuType: 'C',
            redirect: '',
            menuName: '费用配置',
            component: 'order-food-online/menu-management/expense-allocation'
          },
          {
            path: 'food-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '菜品管理',
            component: 'order-food-online/menu-management/food-management'
          }
        ]
      },
      {
        path: 'order-food-online',
        icon: 'DishDot',
        menuType: 'M',
        redirect: '',
        menuName: '订餐记录',
        component: '',
        children: [
          {
            path: 'internal-staff-ordering',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '员工订餐',
            component: 'order-food-online/ordering-record/internal-staff-ordering'
          },
          {
            path: 'staff-help-visitors-to-order-meals',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '员工帮访客订餐',
            component: 'order-food-online/ordering-record/staff-help-visitors-to-order-meals'
          },
          {
            path: 'third-party-unit-order',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '第三方单位订单',
            component: 'order-food-online/ordering-record/third-party-unit-order'
          }
        ]
      }
    ]
  }
]

export default ROUTES
