import request from '@/utils/request'

// 运维中心接口
const baseURL = process.env.VUE_APP_OMCS_API

// 待办事项统计
export function toDoStatistics() {
  return request({
    url: '/audit/processApply/handle',
    method: 'get'
  })
}

// 待我办理
export function getUpcomingList(params) {
  return request({
    url: '/audit/processApply/runList',
    method: 'get',
    params
  })
}

// 我已处理
export function getDoneList(params) {
  return request({
    url: '/audit/processApply/completedList',
    method: 'get',
    params
  })
}

// 待办列表——新接口
export function pendingList(params) {
  return request({
    url: '/audit/processAudit/pendingList',
    method: 'get',
    params
  })
}

// 我的收藏
export function getCollectionList(params) {
  return request({
    url: '/operation-basic/collect/queryList',
    method: 'get',
    params
  })
}

// 快速导航
export function getQuickNavigation(params) {
  return request({
    baseURL,
    url: '/devopsMenu/parkMenuList',
    method: 'get',
    params
  })
}

// 通知公告列表
export function getNoticeList(params) {
  return request({
    url: '/operation-basic/announcement/list',
    method: 'get',
    params
  })
}

// 查询通知详情
export function getNoticeDetail(id) {
  return request({
    url: `/operation-basic/announcement/get?id=${id}`,
    method: 'get'
  })
}

// 查看我的快捷导航菜单
export function findUserOftenMenuList(params) {
  return request({
    url: '/basic/workToDo/findUserOftenMenuList',
    method: 'get',
    params
  })
}

// 保存我的快捷导航菜单
export function setUserOftenMenu(data) {
  return request({
    url: '/basic/workToDo/setUserOftenMenu',
    method: 'post',
    data
  })
}

// 消息管理
export function messageList(params) {
  return request({
    url: '/operation-basic/message/list',
    method: 'get',
    params
  })
}

// 消息管理详情
export function getMessage(params) {
  return request({
    url: `/operation-basic/message/get/${params}`,
    method: 'get'
  })
}
