/**
 * 根据target对象，获取filterData中KEY值一样的的数据
 * @param {Object} target
 * @param {Object} filterData
 */
export const filterParams = (target, filterData) => {
  if (getType(target) !== 'Object' && getType(filterData) !== 'Object') {
    return target
  }
  for (const key in target) {
    target[key] = filterData[key] || ''
  }
}

// 获取数据类型
export const getType = (target) => {
  return Object.prototype.toString.call(target).split(' ')[1].replace(']', '')
}
