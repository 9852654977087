import request from '@/utils/request'

// 单点登录
export function ssoLogin() {
  return request({
    url: '/auth/cas/checkLogin',
    method: 'get'
  })
}

// 登陆
export function login(data) {
  return request({
    url: '/auth/commonLogin',
    method: 'post',
    data
  })
}

// 退出登陆
export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'post',
    data
  })
}

// 获取部门树列表
export function getDepartmentList(params) {
  return request({
    url: '/basic/dept/getListTree',
    method: 'get',
    params
  })
}

// 获取所有部门下拉框列表
export function getDepartmentListForSelect() {
  return request({
    url: '/basic/dept/list/enable',
    method: 'get'
  })
}

// 根据部门查询岗位
export function queryPostByDeptId(params) {
  return request({
    url: '/basic/sysPost/findByDeptId',
    method: 'get',
    params
  })
}

// 获取用户列表
export function getUserList(params) {
  return request({
    url: '/basic/user/list',
    method: 'get',
    params
  })
}

// 获取用户详情
export function getUserDetail(userId, params) {
  return request({
    url: `/basic/user/getUserDetail/${userId}`,
    method: 'get',
    params
  })
}

// 修改用户状态
export function modifyUserStatus(data) {
  return request({
    url: '/basic/user/status',
    method: 'post',
    data
  })
}

// 保存编辑用户
export function updateUserInfo(data) {
  return request({
    url: '/basic/user/update',
    method: 'post',
    data
  })
}

// 上传通用接口
export function uploadFile(data) {
  return request({
    url: '/basic/common/upload',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 获取用户拥有的菜单权限
export function getUserMenu(params) {
  return request({
    url: '/basic/user/getUserMenuList',
    method: 'get',
    params
  })
}

// 获取用户泵站接口
export function getParkList(params) {
  return request({
    url: '/basic/user/getParkList',
    method: 'get',
    params
  })
}

// 选择泵站
export function checkPark(params) {
  return request({
    url: '/basic/user/checkPark',
    method: 'get',
    params
  })
}
// 导出
export function exportUser(data) {
  return request({
    url: '/basic/user/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 导入
export function importTeamLeader(data) {
  return request({
    url: '/basic/user/importTeamLeader',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 发送短信验证码接口
export function phoneLogin(params) {
  return request({
    url: '/auth/phoneLogin',
    method: 'get',
    params
  })
}
// 短信登录接口
export function numberLogin(data) {
  return request({
    url: '/auth/numberLogin',
    method: 'post',
    data
  })
}
// 设置负责人
export function setTeamLeader(data) {
  return request({
    url: '/basic/user/setTeamLeader',
    method: 'post',
    data
  })
}
// 机构人员树
export function getUserDeptListTree(params) {
  return request({
    url: '/basic/dept/getUserDeptListTree',
    method: 'get',
    params
  })
}
// 机构人员树s
export function getUserDeptListTrees(params) {
  return request({
    url: '/basic/dept/getUserDeptListTrees',
    method: 'get',
    params
  })
}
