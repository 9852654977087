import { createRouter, createWebHashHistory } from 'vue-router'
import BlankLayout from '@/layout/BlankLayout.vue'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      // component: Layout,
      redirect: '/workbench'
    },
    {
      path: '/login',
      component: () => import('../views/login-page.vue')
    },
    {
      path: '/no-permission',
      component: () => import(/* webpackChunkName: "404" */ '../views/error-page.vue')
    },
    {
      path: '/workbench', // 工作台
      component: BlankLayout,
      redirect: '/workbench/workbench-home', // 重定向工作台首页
      meta: {
        title: '工作台',
        children: [
          {
            path: '/workbench/workbench-home',
            title: '工作台首页'
          },
          {
            path: '/workbench/quick-navigation',
            title: '快速导航'
          },
          {
            path: '/workbench/message-reminder',
            title: '消息提醒'
          }
        ]
      },
      children: [
        {
          path: 'workbench-home',
          name: 'workbench-home',
          component: () => import('@/views/workbench/workbench-home.vue'),
          meta: {
            title: '工作台首页'
          }
        },
        {
          path: 'quick-navigation',
          name: 'quick-navigation',
          component: () => import('@/views/workbench/quick-navigation.vue'),
          meta: {
            title: '快速导航'
          }
        },
        {
          path: 'navigation-setting',
          name: 'navigation-setting',
          component: () => import('@/views/workbench/navigation-setting.vue'),
          meta: {
            title: '快速导航设置'
          }
        },
        {
          path: 'notice-announcement',
          name: 'notice-announcement',
          component: () => import('@/views/workbench/notice-announcement.vue'),
          meta: {
            title: '通知公告'
          }
        },
        {
          path: 'message-reminder',
          name: 'message-reminder',
          component: () => import('@/views/workbench/message-reminder.vue'),
          meta: {
            title: '消息提醒'
          }
        }
      ]
    }
  ]
})
// 前端未找到页面路由（固定不用改）
export const notFoundRouter = {
  path: '/:catchAll(.*)',
  redirect: '/no-permission'
}

export default router
