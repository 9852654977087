import axios from 'axios'
import store from '@/store'
import { getToken, getParkId } from '@/utils/auth'
import { ElMessage } from 'element-plus'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// request 拦截
service.interceptors.request.use(
  (config) => {
    // 可以在请求头设置token
    // 可以处理请求体格式
    if (store.getters.token) {
      config.headers['token'] = getToken()
      config.headers['parkId'] = getParkId()
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response 拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // 可以处理各种状态码
    // ...
    if (res.code === '00003') {
      store.commit('RESET_STATE')
      store.commit('RESET_ROUTES')
      store.commit('SET_NO_PERMISSION', false)
      window.location.href = res.msg
    } else if (res.code !== '00000' && res.code) {
      ElMessage({
        type: 'error',
        message: res.msg
      })
    }
    return res
  },
  (error) => {
    if (error.response.data.code === '00001') {
      store.commit('RESET_STATE')
      window.location.reload()
    } else if (error.response.data.code === '00003') {
      store.commit('RESET_STATE')
      store.commit('RESET_ROUTES')
      store.commit('SET_NO_PERMISSION', false)
      window.location.href = error.response.data.msg
    } else {
      ElMessage({
        type: 'error',
        message: '服务异常，请稍后再试!'
      })
      return Promise.resolve(error)
    }
  }
)

export default service
