import { asyncRouterMap } from '@/config/router.config'
import store from '@/store'
import { sortTree } from '@/utils/treeFormatUtil'

/**
 * 测试环境router.config.js数据生成菜单
 * @returns {Promise<Router>}
 */
export const generatorDevRouter = (routes, parentPath) => {
  let result = [] // 结果数组
  for (const route of routes) {
    if (route.component) {
      // 如果有component，证明是一个有效路由
      let component
      if (route.component === 'ModuleLayout' || route.component === 'BlankLayout') {
        component = () => import(`../layout/${route.component}.vue`)
      } else {
        component = () => import(`../views/${route.component}.vue`)
      }
      const temp = {
        path: parentPath ? `/${parentPath}/${route.path}` : `/${route.path}`,

        component,
        // redirect: `${route.redirect || ''}`,
        redirect: getRedirectPath(route),
        name: route.path,
        meta: {
          title: route.menuName,
          icon: route.icon,
          visible: route.visible,
          hiddenChildren: route.hiddenChildren
        }
      }
      if (route.menuType === 'M' && route.children && route.children.length) {
        temp.children = generatorDevRouter(route.children, route.path)
      }
      if (route.menuType === 'C' && route.children && route.children.length) {
        temp.meta.permission = createListByMenu(route.children)
      }
      result.push(temp)
    } else {
      // 如果没有component,则是一个目录或者是一个按钮
      if (route.menuType === 'M' && route.children && route.children.length) {
        // 空目录
        const childrenResult = generatorDevRouter(route.children, route.path)
        result = result.concat(childrenResult)
      }
    }
  }
  return result
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (userMenuList) => {
  return new Promise((resolve) => {
    // 扁平数组转树形结构 后端数据, 根级 PID
    const treeMenu = arrayToTree(userMenuList, '0')

    // 根据菜单orderNum字段排序
    sortTree(treeMenu)
    store.commit('SET_MENU_ROUTES', treeMenu)
    const routes = generatorDevRouter(treeMenu)
    resolve(routes)
  })
}

/**
 * 扁平菜单数组转树形菜单结构
 * 最优性能 map 存储
 *
 * @param {*} list 扁平数组
 * @param {*} parentId 顶级父Id
 * @return {*}
 */
function arrayToTree(list, parentId) {
  const result = [] // 存放结果集
  const itemMap = {} // map 存储
  for (const item of list) {
    const id = item.id
    const pid = item.parentId

    if (!itemMap[id]) {
      itemMap[id] = {
        children: []
      }
    }

    itemMap[id] = {
      ...item,
      key: item.menuKey,
      path: item.path || item.menuKey,
      name: item.menuKey,
      children: itemMap[id]['children']
    }

    const treeItem = itemMap[id]

    if (pid === parentId) {
      result.push(treeItem)
    } else {
      if (!itemMap[pid]) {
        itemMap[pid] = {
          children: []
        }
      }
      itemMap[pid].children.push(treeItem)
    }
  }
  return result
}

// 生成菜单权限数据
function createListByMenu(menuList) {
  const permission = []
  for (const menu of menuList) {
    permission.push(menu.perms)
  }
  return permission
}

// 根据动态菜单获取重定向地址
function getRedirectPath(route) {
  if (route.menuType === 'M' && route.children && route.children.length) {
    if (route.children[0].menuType === 'M' && route.children[0].children && route.children[0].children.length) {
      return `/${route.path}/${route.children[0].children[0].path}`
    } else {
      return `/${route.path}/${route.children[0].path}`
    }
  } else {
    return ''
  }
}
