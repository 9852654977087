import request from '@/utils/request'

// 运维中心接口
const baseURL = process.env.VUE_APP_OMCS_API

// 查询字典类型
export function getDictTypeList(dictType) {
  return request({
    baseURL,
    url: `/sysDictType/list?dictType=${dictType}`,
    method: 'get'
  })
}

// dictType为固定类型：
// 到访事由 visit_reason
// 未出园提醒  not_out_park_remind
// 滞留提醒时间  stranded_remind_time
// 公告类型  announcement_type
// 新闻类型  news_type
// 活动类型  activity_type
// 保养周期  maintenance_cycle
export function getDictList(params) {
  return request({
    url: `/system/dict/getList?dictType=${params}`,
    methods: 'get'
  })
}
