import Cookies from 'js-cookie'

const TokenName = 'nansha_token'
const ParkIdName = 'nansha_park_id'
const UserIdName = 'nansha_user_id'
const ParkListName = 'nansha_park_list'

export function getToken() {
  return Cookies.get(TokenName)
}

export function setToken(token) {
  return Cookies.set(TokenName, token)
}

export function removeToken() {
  return Cookies.remove(TokenName)
}

export function getParkId() {
  return Cookies.get(ParkIdName)
}

export function setParkId(parkId) {
  return Cookies.set(ParkIdName, parkId)
}

export function removeParkId() {
  return Cookies.remove(ParkIdName)
}

export function getUserId() {
  return Cookies.get(UserIdName)
}

export function setUserId(userId) {
  return Cookies.set(UserIdName, userId)
}

export function removeUserId() {
  return Cookies.remove(UserIdName)
}

export function getParkLists() {
  return Cookies.get(ParkListName)
}

export function setParkLists(parkList) {
  return Cookies.set(ParkListName, parkList)
}

export function removeParkLists() {
  return Cookies.remove(ParkListName)
}
