/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { generatorDynamicRouter, generatorDevRouter } from '@/router/generator-routers'
import { asyncRouterMap } from '@/config/router.config'
import { getUserMenu } from '@/api/common/user'
import { getParkId, getUserId } from '@/utils/auth'
import { isURL } from '@/utils/treeFormatUtil'
import { ElMessage } from 'element-plus'
const permission = {
  state: {
    menuRoutes: [],
    addRoutes: [],
    routePermission: [],
    flatMenu: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
    },
    SET_MENU_ROUTES: (state, routes) => {
      state.menuRoutes = routes
    },
    SET_ROUTE_PERMISSION: (state, permissionList) => {
      state.routePermission = permissionList
    },
    SET_FLAT_MENU: (state, data) => {
      state.flatMenu = data
    },
    RESET_ROUTES: (state) => {
      state.menuRoutes = []
      state.addRoutes = []
      state.routePermission = []
      state.flatMenu = []
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getUserMenu({
          ascriptionType: 1,
          userId: getUserId(),
          parkId: getParkId(),
          visible: 0
        }).then((res) => {
          if (res.code === '00000') {
            for (const item of res.data) {
              let URL = (item.path || item.menuKey || '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
              if (isURL(URL)) {
                item['path'] = `i-${item.id}`
                item['iframeURL'] = URL
              }
            }
            commit('SET_FLAT_MENU', res.data)
            generatorDynamicRouter(res.data).then((treeRoutes) => {
              // 用户没有菜单权限也不阻止
              commit('SET_ROUTES', treeRoutes)

              // 如果用户没有菜单权限，阻止访问，并退出登录
              // if (treeRoutes.length > 0) {
              //   commit('SET_ROUTES', treeRoutes)
              // } else {
              //   ElMessage({
              //     type: 'warning',
              //     message: '该用户无菜单权限'
              //   })
              //   commit('RESET_STATE')
              // }
              resolve(treeRoutes.length)
            })
          } else {
            reject()
          }
        })
      })
    }
  }
}

export default permission
