const ROUTES = [
  {
    path: 'oam-services',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/oam-services/device-management',
    menuName: '运维服务',
    component: 'ModuleLayout',
    children: [
      {
        path: 'oam-services',
        icon: 'CreditCard',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '设备管理',
        component: '',
        children: [
          {
            path: 'device-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '设备管理',
            component: 'oam-services/device-management/device-management'
          },
          {
            path: 'device-type-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '设备类型管理',
            component: 'oam-services/device-management/device-type-management'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'Help',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '设备维修',
        component: '',
        children: [
          {
            path: 'order-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '工单管理',
            component: 'oam-services/device-maintain/order-management'
          },
          {
            path: 'warn-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '告警管理',
            component: 'oam-services/device-maintain/warn-management'
          },
          {
            path: 'auto-dispatch',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '自动派单管理',
            component: 'oam-services/device-maintain/auto-dispatch'
          },
          {
            path: 'order-statistical',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '工单统计',
            component: 'oam-services/device-maintain/order-statistical'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'TrendCharts',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '巡更管理',
        component: '',
        children: [
          {
            path: 'watchpoint-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '巡更点管理',
            component: 'oam-services/watchman-management/watchpoint-management'
          },
          {
            path: 'watchway-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '巡更路线管理',
            component: 'oam-services/watchman-management/watchway-management'
          },
          {
            path: 'watchtask-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '巡更任务管理',
            component: 'oam-services/watchman-management/watchtask-management'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'WindPower',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '灌溉管理',
        component: '',
        children: [
          {
            path: 'water-device',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '灌溉设备',
            component: 'oam-services/water-management/water-device'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'HomeFilled',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '智能家居管理',
        component: '',
        children: [
          {
            path: 'household-device',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '智能家居设备',
            component: 'oam-services/household-management/household-device'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'User',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '访客管理',
        component: '',
        children: [
          {
            path: 'visitors-qrcode',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '访客登记二维码',
            component: 'oam-services/visitors-management/visitors-qrcode'
          },
          {
            path: 'visitors-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '访客管理',
            component: 'oam-services/visitors-management/visitors-management'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'DataAnalysis',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '会议管理',
        component: '',
        children: [
          {
            path: 'conference-room-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '会议室管理',
            component: 'oam-services/conference-management/conference-room-management'
          },
          {
            path: 'conference-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '会议管理',
            component: 'oam-services/conference-management/conference-management'
          }
        ]
      },
      {
        path: 'oam-services',
        icon: 'Notification',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '厂区互动',
        component: '',
        children: [
          {
            path: 'notices-announcements',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '通知公告',
            component: 'oam-services/factory-interaction/notices-announcements'
          },
          {
            path: 'activity-center',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '活动中心',
            component: 'oam-services/factory-interaction/activity-center'
          },
          {
            path: 'news-information',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '新闻资讯',
            component: 'oam-services/factory-interaction/news-information'
          }
        ]
      }
    ]
  }
]
export default ROUTES
