import request from '@/utils/request'
// -------------------------运维服务-会议管理-会议管理-------------------------
const api = '/operation-meeting/meetingApply/'
// 列表
export function meetingApplyList(params) {
  return request({
    url: api + 'list',
    method: 'get',
    params
  })
}
// 预约(新增)
export function saveMeetingApply(data) {
  return request({
    url: api + 'save',
    method: 'post',
    data
  })
}
// 修改
export function updateMeetingApply(data) {
  return request({
    url: api + 'update',
    method: 'post',
    data
  })
}
// 详情
export function getMeetingApply(params) {
  return request({
    url: `${api}get/${params}`,
    method: 'get'
  })
}
// 取消
export function checkMeetingApply(data) {
  return request({
    url: api + 'check',
    method: 'post',
    data
  })
}
// 审核流程
export function getProcessApply(params) {
  return request({
    url: `/audit/processApply/get/${params}`,
    method: 'get'
  })
}
// 审核
export function auditProcessApply(data) {
  return request({
    url: '/audit/processApply/audit',
    method: 'post',
    data
  })
}
// 列表_导出
export function deriveDatas(params) {
  return request({
    url: api + 'deriveDatas',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
// 签到
export function updateSign(data) {
  return request({
    url: api + 'updateSign',
    method: 'post',
    data
  })
}
