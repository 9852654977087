import store from '@/store'
import { getType } from '@/utils/helper'
export default {
  install(app) {
    /**
     * 按钮权限指令
     * example
     * 字符串：v-action:add
     * 对象： v-action="{ name: 'add', text: '没有权限的情况下组件替成文字' }"
     */
    app.directive('action', {
      beforeMount(el, binding) {
        const roles = store.getters.roles
        if (!roles.length) {
          return
        }

        const params = binding.arg || binding.value
        let actionName = ''
        const type = getType(params)
        const arr = []
        if (type === 'Object') {
          actionName = params.name
          if (roles.indexOf(actionName) === -1) {
            const dom = document.createElement('span')
            dom.innerHTML = params.text
            dom.style.fontSize = '14px'
            el.replaceChildren(dom)
          }
        } else if (type === 'String') {
          actionName = params
          if (roles.indexOf(actionName) === -1) {
            ;(el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
          }
        } else {
          throw 'v-action 指令的参数只能是对象或字符串！'
        }
      }
    })
  }
}
