import request from '@/utils/request'

const isNewVersion = () => {
  const url = `//${window.location.host}/version.json`
  request.get(url).then((res) => {
    const version = res.version
    console.log(res, '版本号')
    const localVersion = localStorage.getItem('version')
    if (localVersion && localVersion !== version) {
      if (confirm('系统检测到有新版本，是否更新？')) {
        localStorage.setItem('version', version)
        window.location.reload()
        return
      }
    } else {
      localStorage.setItem('version', version)
    }
  })
}

export default {
  isNewVersion
}
