// 员工宿舍
const ROUTES = [
  {
    path: 'staff-dorm',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/staff-dorm/dorm-management',
    menuName: '员工宿舍',
    component: 'ModuleLayout',
    children: [
      {
        path: 'staff-dorm',
        icon: 'OfficeBuilding',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '宿舍管理',
        component: '',
        children: [
          {
            path: 'dorm-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '宿舍管理',
            component: 'staff-dorm/dorm-management/dorm-management'
          },
          {
            path: 'dorm-statistics',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '宿舍入住统计',
            component: 'staff-dorm/dorm-management/dorm-statistics'
          }
        ]
      },
      {
        path: 'staff-dorm',
        icon: 'List',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '宿舍申请管理',
        component: '',
        children: [
          {
            path: 'dorm-application',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '住宿申请',
            component: 'staff-dorm/dorm-application/dorm-application'
          },
          {
            path: 'withdrawal-application',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '退宿申请',
            component: 'staff-dorm/dorm-application/withdrawal-application'
          }
        ]
      },
      {
        path: 'staff-dorm',
        icon: 'Ticket',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '费用管理',
        component: '',
        children: [
          {
            path: 'internal-accommodation-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '内部住宿费用管理',
            component: 'staff-dorm/expense-management/internal-accommodation-management'
          },
          {
            path: 'visitor-accommodation-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '访客住宿费用管理',
            component: 'staff-dorm/expense-management/visitor-accommodation-management'
          }
        ]
      },
      {
        path: 'staff-dorm',
        icon: 'Suitcase',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '住宿管理',
        component: '',
        children: [
          {
            path: 'online-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '在线人员管理',
            component: 'staff-dorm/accommodation-management/online-management'
          },
          {
            path: 'historical-checkIn',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '历史入住记录',
            component: 'staff-dorm/accommodation-management/historical-checkIn'
          }
        ]
      }
    ]
  }
]

export default ROUTES
