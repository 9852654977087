const ROUTES = [
  {
    path: 'safe-passage',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/safe-passage/access-facility-management',
    menuName: '安全通行',
    component: 'ModuleLayout',
    children: [
      {
        path: 'safe-passage',
        icon: 'Avatar',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '通行管理',
        component: '',
        children: [
          {
            path: 'access-facility-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '通行设施管理',
            component: 'safe-passage/traffic-management/access-facility-management'
          },
          {
            path: 'configuring-relationships',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '配置通行设施关系',
            component: 'safe-passage/traffic-management/configuring-relationships'
          },
          {
            path: 'configuring-policy',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '配置通行设施运行策略',
            component: 'safe-passage/traffic-management/configuring-policy'
          },
          {
            path: 'personnel-pass-record',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '人员通行记录',
            component: 'safe-passage/traffic-management/personnel-pass-record'
          },
          {
            path: 'face-database-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '人脸库管理',
            component: 'safe-passage/traffic-management/face-database-management'
          },
          {
            path: 'people-group-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '人员群组管理',
            component: 'safe-passage/traffic-management/people-group-management'
          }
        ]
      },

      {
        path: 'safe-passage',
        icon: 'List',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '通行区域管理',
        component: '',
        children: [
          {
            path: 'area-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '通行区域管理',
            component: 'safe-passage/area-management/area-management'
          },
          {
            path: 'personnel-access',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '人员通行权限',
            component: 'safe-passage/area-management/personnel-access'
          }
        ]
      },
      {
        path: 'safe-passage',
        icon: 'Stamp',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '停车管理',
        component: '',
        children: [
          {
            path: 'parking-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '停车场管理',
            component: 'safe-passage/park-management/parking-management'
          },
          {
            path: 'vehicle-management',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '车辆管理',
            component: 'safe-passage/park-management/vehicle-management'
          },
          {
            path: 'vehicle-entry-record',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '车辆入场记录（场内车辆）',
            component: 'safe-passage/park-management/vehicle-entry-record'
          },
          {
            path: 'vehicle-attendance-record',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '车辆出场记录',
            component: 'safe-passage/park-management/vehicle-attendance-record'
          }
        ]
      }
    ]
  }
]
export default ROUTES
