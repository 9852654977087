<template>
  <div class="top-menu">
    <div class="left-layout">
      <div class="logo">
        <img class="logo-img" src="../../assets/logo.png" />
      </div>
      <div class="menu-list my-top-menu">
        <!-- {{ state.activeIndex }} -->
        <el-menu
          :default-active="state.activeIndex"
          class="el-menu-top"
          mode="horizontal"
          :ellipsis="false"
          background-color="#3E5DDC"
          text-color="#ced6f6"
          active-text-color="#FFFFFF"
          router>
          <el-menu-item :index="`/workbench/workbench-home`" @click="change">工作台</el-menu-item>
          <template v-for="item in state.menuData" :key="`/${item.path}`">
            <el-menu-item v-if="item.visible === '0'" :index="`/${item.path}`">
              {{ item.menuName }}
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="right-layout">
      <!-- 泵站选择 -->
      <div v-if="store.getters.allParkList.length" class="pumping-station-class" @click="openPumpingStation">
        <el-icon class="icon-class"><Switch /></el-icon>
        <span class="span-class">{{ park }}</span>
      </div>

      <div class="pumping-station-class">
        <el-tooltip class="box-item" effect="dark" content="帮助文档" placement="bottom">
          <!-- <el-icon class="icon-class" @click="openPDF"><WarningFilled /></el-icon> -->
          <img class="img-class" src="../../assets/workbench/help.png" @click="openPDF" />
        </el-tooltip>
      </div>

      <el-dropdown>
        <div class="user-warp">
          <img class="avatar" src="../../assets/user-avatar.png" />
          <span>{{ userInfo.userName }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- <el-dropdown-item v-if="isShow" @click="toLink('/system-management/common-problem')">
              常见问题
            </el-dropdown-item> -->
            <el-dropdown-item @click="commonProblem.init()">常见问题</el-dropdown-item>
            <el-dropdown-item @click="dialogVisible = true">个人中心</el-dropdown-item>

            <!-- 暂时保留退出登录功能 -->
            <el-dropdown-item divided @click="logout">重新登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dialog v-model="dialogVisible" title="个人信息" width="800px">
        <div class="info-content">
          <el-form class="form-warp" label-width="100px">
            <el-form-item label="姓名：">{{ userInfo.userName }}</el-form-item>
            <el-form-item label="身份证号：">{{ userInfo.idCard }}</el-form-item>
            <el-form-item label="账号角色：">{{ userInfo.roleName }}</el-form-item>
            <el-form-item label="组织机构：">{{ userInfo.deptName }}</el-form-item>
            <el-form-item label="关联区域：">{{ park }}</el-form-item>
          </el-form>
          <el-form class="form-warp" label-width="100px">
            <el-form-item label="联系方式：">{{ userInfo.phonenumber }}</el-form-item>
            <el-form-item label="邮箱：">{{ userInfo.email }}</el-form-item>
            <el-form-item label="职务：">{{ userInfo.companyDuty }}</el-form-item>
            <el-form-item label="类型：">
              {{
                userInfo.userTypeLx == 1 ? '普通员工' : userInfo.userTypeLx == 4 ? '应届毕业生' : userInfo.userTypeLx
              }}
            </el-form-item>
          </el-form>
          <div class="div-class">
            <!-- 头像组件 -->
            <el-image
              v-if="userInfo.avatar"
              style="width: 100px; height: 100px; border-radius: 3px"
              :src="userInfo.avatar"
              :preview-src-list="[userInfo.avatar]"
              preview-teleported="true"
              fit="cover" />
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>
      <!-- 泵站弹窗 -->
      <el-dialog
        v-model="pumpingStationdialogVisible"
        title="请选择区域"
        :top="'10vh'"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="650px"
        center>
        <!-- :show-close="false" -->
        <div style="text-align: center">
          <el-checkbox-group v-model="pumpingStation">
            <el-checkbox v-for="(item, index) in parkList" :key="index" :label="item.parkId" size="large" border>
              {{ item.parkName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <template v-slot:footer>
          <el-button type="primary" @click="changePumpingStation">确定</el-button>
        </template>
      </el-dialog>
    </div>
    <CommonProblem ref="commonProblem" />
    <MessageDetail ref="messageDetail" @ok="getOk" />
  </div>
</template>

<script setup>
  import { reactive, ref, watchEffect } from 'vue'
  import { useRouter } from 'vue-router'
  import store from '@/store'
  import { setParkId, getParkId, setParkLists } from '@/utils/auth'
  import { checkPark } from '@/api/common/user'
  import { ElMessage, ElNotification } from 'element-plus'
  import CommonProblem from '@/views/system-management/problem-management/common-problem.vue'
  import MessageDetail from '@/views/workbench/components/message-detail.vue'
  import { getDictTypeList } from '@/api/common/dictType'

  const docUrl = ref('')
  const messageDetail = ref()
  const commonProblem = ref()
  const router = useRouter()
  const dialogVisible = ref(false)
  const pumpingStationdialogVisible = ref(false)
  const userName = ref('')
  const avatar = ref('')
  const userInfo = ref({})
  const parkList = ref([])
  const park = ref('')
  const pumpingStation = ref([])
  // const isShow = ref(false)
  const state = reactive({
    menuData: store.getters.configRoutes,
    activeIndex: router.currentRoute.value.matched[0].path
  })

  // const getName = () => {
  //   const result = findIndexArray(JSON.parse(JSON.stringify(state.menuData)), 'common-problem_view')
  //   result ? (isShow.value = true) : (isShow.value = false)
  // }
  // const findIndexArray = (data, current) => {
  //   var result = ''
  //   if (!data) {
  //     return
  //   }
  //   for (var i in data) {
  //     var item = data[i]
  //     if (item.path == current) {
  //       result = item
  //       break
  //     } else if (item.children && item.children.length > 0 && !result) {
  //       result = findIndexArray(item.children, current)
  //     }
  //   }
  //   return result
  // }
  // getName()

  store.dispatch('GetUserInfo').then((res) => {
    userInfo.value = store.getters.userInfo
  })

  const setParkList = () => {
    parkList.value = store.getters.allParkList
    pumpingStation.value = []
    // 已选择泵站
    const arr = store.getters.parkList
    // 已选择泵站名
    const name = []
    arr.forEach((e) => {
      name.push(e.parkName)
    })
    park.value = name.join(',')
  }
  setParkList()
  // store.dispatch('GetParkList').then((res) => {
  //   // 获取全部泵站
  //   parkList.value = res || []
  //   pumpingStation.value = []
  //   // 已选择泵站
  //   const arr = store.getters.parkList
  //   // 已选择泵站名
  //   const name = []
  //   arr.forEach((e) => {
  //     name.push(e.parkName)
  //   })
  //   park.value = name.join(',')
  // })
  function change() {
    state.activeIndex = '/workbench/workbench-home'
    console.log(store.getters.isCollapse)
  }
  watchEffect(() => {
    const route = router.currentRoute.value
    const permissionList = route.meta.permission || []

    // 当路由发生变化的时候，获取目标路由的权限按钮
    // 保存到vuex中，提供给页面判断权限使用
    store.commit('SET_ROUTE_PERMISSION', permissionList)

    const pageName = route.path.split('/')[1]
    if (pageName.includes('workbench')) {
      state.activeIndex = '/' + pageName + '/workbench-home'
    } else {
      state.activeIndex = '/' + pageName
    }
  })

  const toLink = (url) => {
    router.push(url)
  }

  const logout = () => {
    store.dispatch('Logout').then(() => {
      router.push('/login')
    })
  }

  const openPumpingStation = () => {
    pumpingStationdialogVisible.value = true
    pumpingStation.value = getParkId().split(',')
  }
  const changePumpingStation = () => {
    if (pumpingStation.value.length == 0) {
      ElMessage({
        type: 'warning',
        message: '请选择区域'
      })
      return
    }

    // 存id
    const e = pumpingStation.value.join(',')
    setParkId(e)
    store.commit('SET_PARK_ID', e)

    // 已选择的泵站
    const arr = []
    pumpingStation.value.forEach((item) => {
      arr.push(parkList.value.find((e) => e.parkId === item))
    })
    // 存list
    setParkLists(arr)
    store.commit('SET_PARK_LIST', arr)

    // 切换后调接口，告诉后台
    getCheckPark(e)
  }
  const getCheckPark = (parkId) => {
    checkPark({ parkId: parkId })
      .then((res) => {
        if (res.code == '00000') {
          ElMessage({
            type: 'success',
            message: '切换成功'
          })
        }
      })
      .catch(() => {
        ElMessage({
          type: 'warning',
          message: '切换失败'
        })
      })
      .finally(() => {
        window.location.reload()
      })
  }

  const isParking = () => {
    if (store.getters.parkList.length == 0 && !store.getters.parkId && store.getters.allParkList.length) {
      // pumpingStationdialogVisible.value = true
      const arr = JSON.parse(JSON.stringify(store.getters.allParkList || []))
      let list = []
      arr.forEach((e) => {
        list.push(e.parkId)
      })
      pumpingStation.value = JSON.parse(JSON.stringify(list || []))
      changePumpingStation()
    }
  }
  isParking()

  // 连接websocket
  const pre = document.location.protocol == 'http:' ? 'ws' : 'wss'
  const httpName = process.env.VUE_APP_ENV === 'production' ? 'zsjzhaf.guangdongwater.com:9098' : '172.16.125.12:34913'
  const ws = new WebSocket(pre + '://' + httpName + '/websocket/' + store.getters.userId)
  // 正式：https://zsjzhaf.guangdongwater.com:9098/   测试：172.16.125.12:34913

  // 监听连接成功，并发送消息
  ws.onopen = function (e) {
    console.log('连接', e)
    ws.send(store.getters.userInfo.userName + ' 用户:' + store.getters.userId + ' 连接成功!')
  }
  // 监听连接关闭事件
  ws.onclose = function () {
    // 监听整个过程中websocket的状态
    console.log('ws连接状态：' + ws.readyState)
  }
  // 监听并处理异常事件
  ws.onerror = function (error) {
    console.log('ws连接异常', error)
  }
  // 监听收到的消息
  ws.onmessage = function (e) {
    if (e.data) {
      // const str = eval('(' + e.data + ')')
      console.log('收到的消息', JSON.parse(e.data))
      ElNotification({
        title: '收到一条消息',
        message: '',
        type: 'success',
        duration: 5000,
        position: 'bottom-right',
        customClass: 'notification-custom-class',
        onClick() {
          messageDetail.value.getDetail({ ...JSON.parse(e.data) })
        }
      })
      getOk()
    }
  }

  const getOk = () => {
    let num = Number(store.getters.messageState)
    num = num + 1
    store.commit('SET_MESSAGE_STATE', num)
  }

  const dictList = () => {
    getDictTypeList('help_documentation').then((res) => {
      if (res.code == '00000') {
        docUrl.value = res.rows[0].remark || ''
      }
    })
    // const protocol = window.location.protocol
    // const hostname = window.location.hostname
    // const port = window.location.port
    // docUrl.value = protocol + '//' + hostname + ':' + port + '/help_documentation.pdf'
  }
  dictList()

  const openPDF = () => {
    if (docUrl.value) {
      let a = window.document.createElement('a')
      a.href = docUrl.value
      a.target = '_blank'
      a.click()
    } else {
      ElMessage({
        type: 'warning',
        message: '请联系管理员到运维中心配文档地址。'
      })
    }
  }
</script>
<style>
  /* 消息推送弹窗 */
  .notification-custom-class {
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
  .top-menu {
    height: 60px;
    background: #3e5ddc;
    display: flex;
    justify-content: space-between;
    .left-layout {
      display: flex;
    }
    .pumping-station-class {
      margin-right: 20px;
      // width: 120px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      .icon-class {
        font-size: 24px;
        margin-right: 6px;
      }
      .img-class {
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
      .span-class {
        font-size: 16px;
      }
    }
    .right-layout {
      margin-right: 20px;
      display: flex;
      align-items: center;
      .user-warp {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        border-radius: 16px;
        padding-right: 16px;
        cursor: pointer;
        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 6px;
        }
      }
    }

    .logo {
      flex-shrink: 0;
      width: 260px;
      height: 100%;
      background: #3e5ddc;
      padding-left: 30px;
      box-sizing: border-box;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-img {
        width: 220px;
      }
    }
    .menu-list {
      .el-menu-top {
        height: 100%;
        border-bottom: none;
      }
    }
  }
  .my-top-menu {
    :deep(.el-menu-item.is-active) {
      border-bottom: none;
      position: relative;
      &::before {
        content: '';
        width: 36px;
        height: 4px;
        background: #ffffff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    :deep(.el-menu-item) {
      border: none;
    }
  }
  .info-content {
    border-top: 1px dashed #ececec;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    color: #333333;
    font-size: 16px;
    .form-warp {
      // width: 50%;
      flex: 1;
    }
    .div-class {
      padding-left: 20px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
</style>
