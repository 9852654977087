import { login, logout, getUserMenu, getUserDetail, getParkList, ssoLogin, checkPark } from '@/api/common/user'
import { ElMessage, ElMessageBox } from 'element-plus'

import { generatorDynamicRouter } from '@/router/generator-routers'
import store from '@/store'
import {
  setToken,
  setParkId,
  setUserId,
  setParkLists,
  getToken,
  getParkId,
  getUserId,
  getParkLists,
  removeToken,
  removeParkId,
  removeUserId,
  removeParkLists
} from '@/utils/auth'
import { filterParams } from '@/utils/helper'

const getDefaultState = () => {
  return {
    token: getToken() || null,
    parkId: getParkId() || null,
    userId: getUserId() || null,
    userInfo: JSON.parse(localStorage.getItem('user_info') || '{}'),
    userMenu: [],
    parkList: getParkLists() || [],
    allParkList: [],
    messageState: 0,
    noPermission: false, // 是否是一个没有权限的用户？
    shareRoomTab: 1, //
    isCollapse: false
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    localStorage.setItem('user_info', false)
    removeToken()
    removeParkId()
    removeUserId()
    removeParkLists()
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_PARK_ID: (state, parkId) => {
    state.parkId = parkId
  },
  SET_PARK_LIST: (state, parkList) => {
    state.parkList = parkList
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_ALL_PARK_LIST: (state, parkList) => {
    state.allParkList = parkList
  },
  SET_USER_INFO: (state, info) => {
    const userInfo = {
      avatar: '', // 头像
      idCard: '', // 身份证
      deptName: '', // 部门名称
      roleName: '', // 角色名称
      postName: '', // 岗位名称
      phonenumber: '', // 联系方式
      email: '', // 邮箱
      loginName: '', // 登陆名
      userName: '', // 姓名
      userType: '', // 用户类型
      status: '', // 用户状态
      companyDuty: '', //职务
      userTypeLx: '' //类型
    }
    // 过滤多余参数
    filterParams(userInfo, info)
    state.userInfo = userInfo
    localStorage.setItem('user_info', JSON.stringify(userInfo))
  },
  SET_USER_MENU: (state, menuList) => {
    state.userMenu = menuList
  },
  SET_MESSAGE_STATE: (state, messageState) => {
    state.messageState = messageState
  },
  SET_NO_PERMISSION: (state, val) => {
    state.noPermission = val
  },
  SET_TAB: (state, data) => {
    state.shareRoomTab = data
  },
  SET_IS_COLLAPSE: (state, data) => {
    state.isCollapse = data
  }
}

const actions = {
  // 单点登录
  SsoLogin({ commit }) {
    return new Promise((resolve) => {
      ssoLogin().then((res) => {
        if (res.code === '00000' && res.token) {
          const { token, user, userId } = res
          commit('SET_TOKEN', token)
          // commit('SET_PARK_ID', user.parkId)
          setToken(token)
          // setParkId(user.parkId)
          setUserId(userId)

          // 如果有token就是从数字户门进来的
          if (token) {
            getParkList()
              .then((r) => {
                if (r.code === '00000') {
                  const d = r.data || []
                  // 存全部泵站
                  store.commit('SET_ALL_PARK_LIST', d)
                  store.commit('SET_PARK_LIST', d)
                  // 全部泵站id数组
                  const ids = []
                  if (d && d.length > 0) {
                    d.forEach((e) => {
                      ids.push(e.parkId)
                    })
                  }
                  // 全部parkId
                  const pid = ids && ids.length > 0 ? ids.join(',') : ''
                  // store存parkId
                  commit('SET_PARK_ID', pid)
                  // cookie存parkId
                  setParkId(pid)
                  // 切换泵站
                  checkPark({ parkId: pid })
                }
              })
              .finally(() => {
                resolve()
              })
          }
          // resolve()
        } else {
          resolve()
        }
      })
    })
  },
  // 用户登陆
  Login({ commit }, formData) {
    return new Promise((resolve, reject) => {
      // numberLogin(formData) // 短信验证码的登录
      login(formData)
        .then((res) => {
          if (res.code === '00000') {
            const { token, user, userId } = res
            commit('SET_TOKEN', token)
            commit('SET_PARK_ID', user.parkId)
            setToken(token)
            setParkId(user.parkId)
            setUserId(userId)
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  Logout({ commit }) {
    return new Promise((resolve) => {
      logout().then(() => {
        commit('RESET_STATE')
        commit('RESET_ROUTES')
        commit('SET_NO_PERMISSION', false)
        resolve()
      })
    })
  },
  GetUserMenu({ commit }) {
    return new Promise((resolve, reject) => {
      getUserMenu({
        ascriptionType: 1,
        userId: getUserId(),
        parkId: getParkId(),
        visible: 0
      }).then((res) => {
        if (res.code === '00000') {
          commit('SET_USER_MENU', res.data)
          generatorDynamicRouter(res.data).then((routes) => {
            resolve(routes)
          })
        } else {
          reject()
        }
      })
    })
  },
  GetUserInfo({ commit }) {
    return new Promise((resolve) => {
      getUserDetail(getUserId()).then((res) => {
        if (res.code === '00000') {
          commit('SET_USER_INFO', res.data)
          resolve(res.data)
        }
      })
    })
  },
  GetParkList({ commit }) {
    return new Promise((resolve) => {
      getParkList().then((res) => {
        if (res.code === '00000') {
          const data = res.data
          store.commit('SET_ALL_PARK_LIST', data)
          // commit('SET_PARK_LIST', res.data)
          // setParkLists(res.data)
          const arr = []
          data.forEach((e) => {
            if (e.check == true) {
              arr.push(e)
            }
          })
          store.commit('SET_PARK_LIST', arr)
          setParkLists(arr)
          resolve(data)

          // 如果没有泵站则提示
          if (data.length) return
          const time = setTimeout(() => {
            ElMessageBox.alert('您的账号未关联区域，请联系管理员！', '提示', {
              confirmButtonText: '确定'
            })
            clearTimeout(time)
          }, 1000)
        }
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
