<template>
  <div class="my-breadcrumb">
    <div v-if="routes && routes.length > 0">
      <!-- <el-icon :size="24" class="icon-class" @click="changeCollapse">
        <Expand v-if="isCollapse" />
        <Fold v-else />
      </el-icon> -->
      <img :src="isCollapse ? openPng : closePng" @click="changeCollapse" class="icon-class" />
    </div>
    <div class="label-text" v-if="routes && routes.length > 0">当前位置：</div>
    <div class="route-text" v-for="(route, index) in routes" :key="route.menuKey">
      <span :class="[index !== routes.length - 1 ? 'route-title' : '']">{{ route.menuName }}</span>
      <span v-if="index !== routes.length - 1" class="separator">></span>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import store from '@/store'

  import openPng from '@/assets/common/open.png'
  import closePng from '@/assets/common/close.png'

  const flatMenu = store.getters.flatMenu

  const router = useRouter()
  const routes = ref([])
  watchEffect(() => {
    const route = router.currentRoute.value
    const result = []
    let findRoute = flatMenu.find((r) => r.menuKey === route.name || `i-${r.id}` === route.name)
    if (findRoute) {
      result.push(findRoute)
      do {
        findRoute = flatMenu.find((r) => r.id === findRoute.parentId)
        if (findRoute) {
          result.push(findRoute)
        }
      } while (findRoute)
    }
    routes.value = result.reverse()
  })

  const toUrl = (index, url) => {
    if (index !== routes.value.length) {
      router.push(url)
    }
  }

  const isCollapse = ref(store.getters.isCollapse)

  watch(
    () => store.getters.isCollapse,
    () => {
      isCollapse.value = store.getters.isCollapse
    }
  )

  const changeCollapse = () => {
    store.commit('SET_IS_COLLAPSE', !store.getters.isCollapse)
  }
</script>

<style lang="scss" scoped>
  .my-breadcrumb {
    display: flex;
    height: 60px;
    align-items: center;
    color: #a7a7a7;
    font-size: 14px;
    line-height: 100%;
    flex-shrink: 0;
    .route-text {
      font-size: 14px;
      display: flex;
      align-items: center;
      .separator {
        margin: 0 6px;
      }
      &:last-child {
        color: #333333;
      }
    }
    .icon-class {
      margin-right: 10px;
      cursor: pointer;
      width: 25px;
      height: 25px;
      vertical-align: middle;
      margin-left: 20px;
    }
  }
</style>
