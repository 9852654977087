export default function formatterValue(row, column, cellValue) {
  if (Object.prototype.toString.call(cellValue) === '[object Number]') {
    return cellValue
  }
  if (cellValue) {
    return cellValue
  } else {
    return '--'
  }
}
