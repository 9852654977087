// 系统设置
const ROUTES = [
  {
    path: 'system-management',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/system-management/common-problem',
    menuName: '系统设置',
    component: 'ModuleLayout',
    children: [
      {
        path: 'common-problem',
        icon: 'QuestionFilled',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '问题管理',
        component: 'system-management/common-problem'
      },
      {
        path: 'data-document',
        icon: 'UploadFilled',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '资料下载',
        component: 'system-management/data-document'
      },
      {
        path: 'system-management',
        icon: 'Notebook',
        visible: '0',
        menuType: 'M',
        redirect: '',
        menuName: '日志管理',
        component: '',
        children: [
          {
            path: 'login-log',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '登录日志',
            component: 'system-management/log-management/login-log'
          },
          {
            path: 'operation-log',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '操作日志',
            component: 'system-management/log-management/operation-log'
          },
          {
            path: 'exception-log',
            icon: '',
            visible: '0',
            menuType: 'C',
            redirect: '',
            menuName: '异常日志',
            component: 'system-management/log-management/exception-log'
          }
        ]
      },
      {
        path: 'service-agreement-configuration',
        icon: 'Collection',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '服务协议配置',
        component: 'system-management/service-agreement-configuration'
      }
    ]
  }
]

export default ROUTES
