const ROUTES = [
  {
    path: 'personnel-management',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/personnel-management/organizational-structure-management',
    menuName: '人员管理',
    component: 'ModuleLayout',
    children: [
      {
        path: 'organizational-structure-management',
        icon: 'OfficeBuilding',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '组织架构管理',
        component: 'personnel-management/organizational-structure-management'
      },
      {
        path: 'post-management',
        icon: 'Management',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '岗位管理',
        component: 'personnel-management/post-management'
      },
      {
        path: 'user-management',
        icon: 'Avatar',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '用户管理',
        component: 'personnel-management/user-management'
      },
      {
        path: 'role-permission-management',
        icon: 'List',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '角色权限管理',
        component: 'personnel-management/role-permission-management'
      },
      {
        path: 'approval-process-management',
        icon: 'Stamp',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '审批流程管理',
        component: 'personnel-management/approval-process-management'
      }
    ]
  }
]
export default ROUTES
