import request from '@/utils/request'

// 列表
export function getCommonProblemList(params) {
  return request({
    url: '/operation-basic/commonProblem/list',
    method: 'get',
    params
  })
}

// 新增问题
export function addCommonProblem(data) {
  return request({
    url: '/operation-basic/commonProblem/save',
    method: 'post',
    data
  })
}

// 修改问题
export function editCommonProblem(data) {
  return request({
    url: '/operation-basic/commonProblem/update',
    method: 'post',
    data
  })
}

// 删除问题
export function delCommonProblem(data) {
  return request({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    url: '/operation-basic/commonProblem/remove',
    method: 'post',
    params: data
  })
}
