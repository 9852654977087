import router from './router'
import { notFoundRouter } from '@/router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'
import store from '@/store'
import { getToken } from '@/utils/auth'
import versionTood from '@/libs/versionUpdate'

NProgress.configure({ showSpinner: false }) // NProgress Configuration
const isProd = process.env.VUE_APP_ENV === 'production'
console.log(isProd)
const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken()
  NProgress.start()
  document.title = getPageTitle(to.meta.title)

  // 检查单点登录状态
  // if (!hasToken) {
  await store.dispatch('SsoLogin')
  // }

  // start progress bar
  // set page title

  if (hasToken) {
    // 检测版本是否与服务器一致
    versionTood.isNewVersion()

    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const noPermission = store.getters.noPermission
      if (!store.getters.addRoutes.length && !noPermission) {
        try {
          const res = await store.dispatch('GenerateRoutes')
          if (res) {
            store.getters.addRoutes.forEach((item) => {
              router.addRoute(item)
            })
            // 增加404页面
            router.addRoute(notFoundRouter)

            await store.dispatch('GetParkList')
            next({ ...to, replace: true })
          } else {
            // 用户没有路由权限，或者菜单数据解析不出路由数据，都视为没有权限
            store.commit('SET_NO_PERMISSION', true)
            await store.dispatch('GetParkList')
            next('/workbench/workbench-home')
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        next()
      }
    }
  } else {
    // await store.dispatch('SsoLogin')

    if (isProd) {
      window.location.href = 'https://zsjzhaf.guangdongwater.com:9098/api/auth/cas/login'
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
