const ROUTES = [
  {
    path: 'workbench',
    icon: '',
    visible: '0',
    menuType: 'M',
    redirect: '/workbench/workbench-home',
    menuName: '工作台',
    component: 'BlankLayout',
    children: [
      {
        path: 'workbench-home',
        icon: '',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '工作台首页',
        component: 'workbench/workbench-home'
      },
      {
        path: 'quick-navigation',
        icon: '',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '快速导航',
        component: 'workbench/quick-navigation'
      },
      {
        path: 'message-reminder',
        icon: '',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '消息提醒',
        component: 'workbench/message-reminder'
      },
      {
        path: 'notice-announcement',
        icon: '',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '通知公告',
        component: 'workbench/notice-announcement'
      },
      {
        path: 'navigation-setting',
        icon: '',
        visible: '0',
        menuType: 'C',
        redirect: '',
        menuName: '快速导航设置',
        component: 'workbench/navigation-setting'
      }
    ]
  }
]
export default ROUTES
